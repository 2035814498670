import React, { Component } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { getDefaultOptions } from "./HSCInputOptions";
import HSCMapButtons from "./HSCMapButtons";
export default class HSCRTFInput extends Component {
  constructor(props) {
    super(props);
    this.state = { val: "", renderInput: false, hasClipboardPermission: false };
  }
  editor = React.createRef();

  handleOnBlur = (_event, editorContents) => {
    let rex = "&amp;nbsp;";
    let rexp = new RegExp(rex);
    editorContents = editorContents.replace(rexp, "&nbsp;");
    this.props.setFormValue(this.props.inputName, editorContents);
    if (
      (this.props.isSignTaskForm == null ||
        this.props.isSignTaskForm === false) &&
      (this.props.isCreateUserForm == null ||
        this.props.isCreateUserForm === false)
    ) {
      this.props.updateInputsModel(this.state.val, this.props.inputName, true);
    }
  };

  componentDidMount() {
    if (
      this.props.isSignTaskForm == null ||
      this.props.isSignTaskForm === false
    ) {
      this.props.setInputValue(this.props.inputName);
      let valueToSet = this.props.getInputValue(this.props.inputName);
      this.setState(
        {
          val: valueToSet,
          renderInput: true,
        },
        () => {
          this.props.setHiddenInputValue(this.props.inputName, this.state.val);
        }
      );
    } else {
      this.setState({
        renderInput: true,
      });
    }
    this.getClipboardPermission();
  }

  getOptionsWithHiddenButtons() {
    const hideButtons = this.props["hideButtons"];
    let options = getDefaultOptions();
    if (hideButtons !== undefined && hideButtons !== "") {
      let buttonList = options.buttonList;
      let stringButtonList = JSON.stringify(buttonList);
      let hideButtonsArray = hideButtons.split(",").map((item) => item.trim());

      for (let index = 0; index < hideButtonsArray.length; index++) {
        let buttonToHide = hideButtonsArray[index].toUpperCase();
        let valueToReplace = HSCMapButtons[buttonToHide];

        stringButtonList = stringButtonList.replaceAll(
          `,"${valueToReplace}"`,
          ""
        );
        stringButtonList = stringButtonList.replaceAll(
          `"${valueToReplace}",`,
          ""
        );
        stringButtonList = stringButtonList.replaceAll(
          `"${valueToReplace}"`,
          ""
        );
      }

      options.buttonList = JSON.parse(stringButtonList);
    }
    options.attributesWhitelist = {
      'all': 'style|data-.+', 
    };
    return options;
  }

  getSunEditorInstance = (sunEditor) => {
    this.editor.current = sunEditor;
  };

  insertClipboardData = async () => {
    try {
      const clipboardItems = await navigator.clipboard.read();

      for (const clipboardItem of clipboardItems) {
        const blob = await clipboardItem.getType("text/html");
        const text = await blob.text();
        this.editor.current.insertHTML(text, true, true, false);
      }
    } catch (err) {
      console.error(err.name, err.message);
    }
  };

  getClipboardPermission = () => {
    navigator.permissions.query({ name: "clipboard-read" }).then((result) => {
      if (result.state === "granted" || result.state === "prompt") {
        this.setState({ hasClipboardPermission: true });
      } else {
        this.setState({ hasClipboardPermission: false });
      }
    });
  };

  handlePaste = (e, cleanData, maxCharCount) => {
    if (this.state.hasClipboardPermission) {
      this.insertClipboardData();
      return false;
    } else {
      return true;
    }
  };

  render() {



    let renderElement = null;
    if (this.state.renderInput) {
      // this.hideButtons();

      let options = this.getOptionsWithHiddenButtons();
      // console.log("options.buttonList => ", options.buttonList)
      options.value = this.state.val;
      
      if (this.props.colores != null && this.props.colores.length > 0) {
        options.colorList = this.props.colores;
      }
      if (this.props.maxHeight != null && this.props.maxHeight.length > 0) {
        options.maxHeight = this.props.maxHeight;
      } else {
        options.maxHeight = 1000;
      }
      if (this.props.minHeight != null && this.props.minHeight.length > 0) {
        options.minHeight = this.props.minHeight;
      } else {
        options.minHeight = 500;
      }

      let marginLeftStyle = '';

      if(this.props["marginLeft"] != undefined && this.props["marginLeft"]!= ""){
        let marginLeftValue = this.props["marginLeft"];
        marginLeftStyle = `padding-left:${marginLeftValue}cm;`;
      }

      let marginRightStyle = '';

      if(this.props["marginRight"] != undefined && this.props["marginRight"]!= ""){
        let marginRightValue = this.props["marginRight"];
        marginRightStyle = `padding-right:${marginRightValue}cm;`;
      }

      options.defaultStyle= `font-size:40pt; ${marginLeftStyle} ${marginRightStyle}`;//margin-right:100% margin-left:1em;
      options.lineAttrReset = '*';

      renderElement = (
        <SunEditor
          disable={this.props.habilitado}
          setOptions={options}
          lang={"es"}
          onBlur={this.handleOnBlur}
          getSunEditorInstance={this.getSunEditorInstance}
          onPaste={this.handlePaste}
        />
      );
    }
    return renderElement;
  }
}

// import React, { useRef, useEffect } from "react";
// import SunEditor from "suneditor-react";
// import "suneditor/dist/css/suneditor.min.css";

// const config = {
//   code: "en",
//   toolbar: {
//     default: "Default",
//     save: "Save",
//     font: "Font",
//     formats: "Formats",
//     fontSize: "Size",
//     bold: "Bold",
//     underline: "Underline",
//     italic: "Italic",
//     strike: "Strike",
//     subscript: "Subscript",
//     superscript: "Superscript",
//     removeFormat: "Remove Format",
//     fontColor: "Font Color",
//     hiliteColor: "Highlight Color",
//     indent: "Indent",
//     outdent: "Outdent",
//     align: "Align",
//     alignLeft: "Align left",
//     alignRight: "Align right",
//     alignCenter: "Align center",
//     alignJustify: "Align justify",
//     list: "List",
//     orderList: "Ordered list",
//     unorderList: "Unordered list",
//     horizontalRule: "Horizontal line",
//     hr_solid: "Solid",
//     hr_dotted: "Dotted",
//     hr_dashed: "Dashed",
//     table: "Table",
//     link: "Link",
//     math: "Math",
//     image: "Image",
//     video: "Video",
//     audio: "Audio",
//     fullScreen: "Full screen",
//     showBlocks: "Show blocks",
//     codeView: "Code view",
//     undo: "Undo",
//     redo: "Redo",
//     preview: "Preview",
//     print: "print",
//     tag_p: "Paragraph",
//     tag_div: "Normal (DIV)",
//     tag_h: "Header",
//     tag_blockquote: "Quote",
//     tag_pre: "Code",
//     template: "Template",
//     lineHeight: "Line height",
//     paragraphStyle: "Paragraph style",
//     textStyle: "Text style",
//     imageGallery: "Image gallery",
//     dir_ltr: "Left to right",
//     dir_rtl: "Right to left",
//     mention: "Mention",
//   },
//   dialogBox: {
//     linkBox: {
//       title: "Insert Link",
//       url: "URL to link",
//       text: "Text to display",
//       newWindowCheck: "Open in new window",
//       downloadLinkCheck: "Download link",
//       bookmark: "Bookmark",
//     },
//     mathBox: {
//       title: "Math",
//       inputLabel: "Mathematical Notation",
//       fontSizeLabel: "Font Size",
//       previewLabel: "Preview",
//     },
//     imageBox: {
//       title: "Insert image",
//       file: "Select from files",
//       url: "Image URL",
//       altText: "Alternative text",
//     },
//     videoBox: {
//       title: "Insert Video",
//       file: "Select from files",
//       url: "Media embed URL, YouTube/Vimeo",
//     },
//     audioBox: {
//       title: "Insert Audio",
//       file: "Select from files",
//       url: "Audio URL",
//     },
//     browser: {
//       tags: "Tags",
//       search: "Search",
//     },
//     caption: "Insert description",
//     close: "Close",
//     submitButton: "Submit",
//     revertButton: "Revert",
//     proportion: "Constrain proportions",
//     basic: "Basic",
//     left: "Left",
//     right: "Right",
//     center: "Center",
//     width: "Width",
//     height: "Height",
//     size: "Size",
//     ratio: "Ratio",
//   },
//   controller: {
//     edit: "Edit",
//     unlink: "Unlink",
//     remove: "Remove",
//     insertRowAbove: "Insert row above",
//     insertRowBelow: "Insert row below",
//     deleteRow: "Delete row",
//     insertColumnBefore: "Insert column before",
//     insertColumnAfter: "Insert column after",
//     deleteColumn: "Delete column",
//     fixedColumnWidth: "Fixed column width",
//     resize100: "Resize 100%",
//     resize75: "Resize 75%",
//     resize50: "Resize 50%",
//     resize25: "Resize 25%",
//     autoSize: "Auto size",
//     mirrorHorizontal: "Mirror, Horizontal",
//     mirrorVertical: "Mirror, Vertical",
//     rotateLeft: "Rotate left",
//     rotateRight: "Rotate right",
//     maxSize: "Max size",
//     minSize: "Min size",
//     tableHeader: "Table header",
//     mergeCells: "Merge cells",
//     splitCells: "Split Cells",
//     HorizontalSplit: "Horizontal split",
//     VerticalSplit: "Vertical split",
//   },
//   menu: {
//     spaced: "Spaced",
//     bordered: "Bordered",
//     neon: "Neon",
//     translucent: "Translucent",
//     shadow: "Shadow",
//     code: "Code",
//   },
// };
// const HSCRTFInput = (props) => {
//   /**
//    * @type {React.MutableRefObject<SunEditor>} get type definitions for editor
//    */
//   const editor = useRef();

//   // The sunEditor parameter will be set to the core suneditor instance when this function is called

//   return (
//     <div>
//       <p> My Other Contents </p>
//       <SunEditor
//         // setOptions={config}
//         lang={'es'}
//         getSunEditorInstance={getSunEditorInstance}
//         onPaste={handlePaste}
//       />
//     </div>
//   );
// };
// export default HSCRTFInput;
