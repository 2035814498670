import "../assets/style.css";
import historyIcon from "../assets/history.svg";
import commentIcon from "../assets/commentIcon.svg";
import doneIcon from "../assets/doneIcon.svg";
import emptyIcon from "../assets/empty-inbox.svg";

const commentPlugin = {
  name: "commentSystem",
  display: "command",
  buttonClass: "se-btn-comment",
  innerHTML: '<svg width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage"><g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-152.000000, -255.000000)" fill="#000000"><path d="M168,281 C166.832,281 165.704,280.864 164.62,280.633 L159.912,283.463 L159.975,278.824 C156.366,276.654 154,273.066 154,269 C154,262.373 160.268,257 168,257 C175.732,257 182,262.373 182,269 C182,275.628 175.732,281 168,281 L168,281 Z M168,255 C159.164,255 152,261.269 152,269 C152,273.419 154.345,277.354 158,279.919 L158,287 L165.009,282.747 C165.979,282.907 166.977,283 168,283 C176.836,283 184,276.732 184,269 C184,261.269 176.836,255 168,255 L168,255 Z M175,266 L161,266 C160.448,266 160,266.448 160,267 C160,267.553 160.448,268 161,268 L175,268 C175.552,268 176,267.553 176,267 C176,266.448 175.552,266 175,266 L175,266 Z M173,272 L163,272 C162.448,272 162,272.447 162,273 C162,273.553 162.448,274 163,274 L173,274 C173.552,274 174,273.553 174,273 C174,272.447 173.552,272 173,272 L173,272 Z" id="comment-2" sketch:type="MSShapeGroup"> </path> </g></g></svg>',

  add: function (core, targetElement) {
    const context = core.context;
    context.commentSystem = {
      targetButton: targetElement,
      comments: [],
    };

    const modal = this.createModal(core);
    const appWrapper = document.querySelector(".se-wrapper ");
    appWrapper.appendChild(modal);
    context.commentSystem.modal = modal;
    context.commentSystem.openModal = this.openModal;
    context.commentSystem.closeModal = this.closeModal;
    context.commentSystem.saveComment = this.saveComment;
    // context.commentSystem.deleteComment = this.deleteComment;
    context.commentSystem.loadReplies = this.loadReplies.bind(this);
    context.commentSystem.addCommentToList = this.addCommentToList.bind(this);

    modal.querySelector('.comments').addEventListener('click', (event) => {
      this.handleCommentActions(event, core);
    });
    window.document.querySelector('.se-wrapper').addEventListener('click', (event) => {
      this.handleCommentedTextActions(event, core);
    });
    modal.querySelector('.dropdown-menu').addEventListener('click', (event) => {
      this.handleElementClickOnHistory(event, core);
    });
  },

  createModal: function (core) {
    // const app = document.getElementById("counter-app")
    const modal = document.createElement("div");
    modal.classList.add("comment-modal");
    modal.style.display = "none";

    modal.innerHTML = `
        <div class="modal-content" style="height: 100%; display: flex; flex-direction: column; background-color: #F9FBFD; padding-left: 20px; padding-right: 20px;">
            <h2>Comentarios</h2>

            <!-- Section 1: Add Comment -->
            <div class="comment-add-section">
                <div class="buttonSection">
                    <button class="comment-add-btn">Agregar Comentario</button>

                    <!-- Section 2: History past comments -->
                    <div class="history-section">
                        <button class="dropdown-toggle" type="button"> 
                        <img src="https://s3.us-west-2.amazonaws.com/cdn.hotwaresc.com/itzamna/static/media/history.svg" alt="History" width="20" height="20">
                        <span class="caret"></span></button>
                        <ul class="dropdown-menu" style="display:none;">
                        </ul>
                    </div>
                </div>

                <textarea class="comment-input" style="display:none;" placeholder="Tu comentario..."></textarea>
                <button class="comment-save" style="display:none;">Agregar</button>
            </div>

            <!-- Section 3: Comment List -->
            <div class="comment-list-section" style="flex-grow: 1; overflow-y: auto;">

              <ul class="comments">
               
              </ul>
            </div>
          </div>`;

    const addButton = modal.querySelector('.comment-add-btn');
    const inputField = modal.querySelector('.comment-input');
    const saveButton = modal.querySelector('.comment-save');
    addButton.addEventListener('click', () => {
      inputField.style.display = (inputField.style.display === 'none') ? 'block' : 'none';
      saveButton.style.display = (saveButton.style.display === 'none') ? 'block' : 'none';
    });

    saveButton.addEventListener('click', (event) => {
      event.preventDefault();

      const success = this.saveComment(core);

      if (success) {
        inputField.value = '';
        inputField.style.display = 'none';
        saveButton.style.display = 'none';
        // Delete the no comments message if it exists
        const noCommentsMessage = modal.querySelector('.no-comments');
        if (noCommentsMessage) {
          noCommentsMessage.remove();
        }
      }
    });

    // Reply to Comment
    const replyButtons = modal.querySelectorAll('.reply');
    replyButtons.forEach((button) => {
      button.addEventListener('click', () => {
        const commentItem = button.closest('.comment'); // Find the closest parent comment element
        const replyInput = commentItem.querySelector('.reply-input-wrapper'); // Find the reply input inside this comment
        if (replyInput) {
          replyInput.style.display = (replyInput.style.display === 'none') ? 'block' : 'none';
        } else {
          console.error('Reply input not found for this comment');
        }
      });
    });

    const doneButtons = modal.querySelectorAll('.done');
    doneButtons.forEach((button) => {
      button.addEventListener('click', () => {
        this.handleDone(button, core);

      });
    });

    // History Dropdown
    const dropdownToggle = modal.querySelector('.dropdown-toggle');
    const dropdownMenu = modal.querySelector('.dropdown-menu');
    dropdownToggle.addEventListener('click', () => {
      dropdownMenu.style.display = (dropdownMenu.style.display === 'none') ? 'block' : 'none';
    });

    window.document.addEventListener("click", (event) => {
      // Check if the clicked element is not inside the dropdown or its button
      if (!dropdownToggle.contains(event.target) && !dropdownMenu.contains(event.target)) {
        if (dropdownMenu.style.display === 'block') {
          dropdownMenu.style.display = 'none';
        }
      }
    });

    return modal;
  },

  handleCommentActions: function (event, core) {
    const addReplyBtn = event.target.closest('.comment-add-btn');
    if (addReplyBtn) {
      const commentId = addReplyBtn.getAttribute('data-id');
      const replyInput = document.querySelector(`.reply-input-id-${commentId}`);

      if (replyInput.value.trim() !== '') {
        this.handleReply(commentId, core, replyInput);

        replyInput.value = '';

        addReplyBtn.closest('.reply-input-wrapper').style.display = 'none';
      } else {
        console.log('Reply input is empty. Please enter a reply.');
      }
    }
    // Handle Done Button
    const doneButton = event.target.closest('.done');
    if (doneButton) {
      this.handleDone(doneButton, core);
    }
  },

  handleDone: function (button, core) {
    const comment = button.closest('li[data-id]');
    const commentId = comment.getAttribute('data-id');
    // Hide the comment li element in the modal. 
    (comment) && (comment.style.display = 'none');
    // Hide comment replies 
    this.handleDisplayOfCommentReplies(commentId, core, true)
    // Remove the highlighted text
    const spanElement = document.querySelector(`span[data-id="${comment.getAttribute('data-id')}"]`);
    if (spanElement) {
      spanElement.style.backgroundColor = 'transparent';
      spanElement.setAttribute('data-isresolved', 'true');
    }
    // Mark all replies as resolved
    this.markRepliesAsResolved(commentId, spanElement, core, true);

    // Add the comment to the history section
    this.addCommentToHistory(comment, core);
  },
  markRepliesAsResolved: function (commentId, spanElement, core, isResolved) {
    if (spanElement) {
      let repliesArray = JSON.parse(spanElement.getAttribute('data-replies') || '[]');
      repliesArray.forEach(replyData => {
        replyData.isResolved = isResolved;
      });
      spanElement.setAttribute('data-replies', JSON.stringify(repliesArray));
    }
  },

  addCommentToHistory: function (comment, core) {
    const historySection = document.querySelector('.dropdown-menu');
      // Check if the comment is a DOM element (li) or an object
    const commentId = comment.getAttribute ? comment.getAttribute('data-id') : comment.commentId;
    
    // Check if the comment already exists in the history list
    const existingComment = historySection.querySelector(`li.comment[data-id="${commentId}"]`);
    
    // If the comment exists, skip adding it
    if (existingComment) {
      return; // Skip adding the comment if it's already in the history
    }

    const savedComment = core.context.commentSystem.comments.find(c => c.commentId == comment.getAttribute('data-id'));
    if (savedComment) {
      if (historySection) {
        // const commentClone = comment.cloneNode(true);
        const commentClone = window.document.createElement('li');
        commentClone.className = 'comment';
        commentClone.setAttribute('data-id', comment.getAttribute('data-id'));
        commentClone.innerHTML = `
        <div class="meta" data-id="${savedComment.commentId}" style="color: #989898; font-size: 12px !important" >
          ${savedComment.author} | ${savedComment.date} 
        </div>
        <div class="body">${savedComment.comment}</div>
      `;
        commentClone.style.display = 'block';
        historySection.appendChild(commentClone);
      }
    }
  },

  handleElementClickOnHistory: function (event, core) {
    const comment = event.target.closest('.comment');
    const modal = core.context.commentSystem.modal;
    if (comment) {
      const commentId = comment.getAttribute('data-id');
      const ulElement = modal.querySelector('.comments');
      const commentLiElement = ulElement.querySelector(`.comment[data-id="${commentId}"]`);
      if (commentLiElement) {
        commentLiElement.style.display = 'block';
        commentLiElement.classList.remove('resolvedComment');
        // Add all replies 
        this.handleDisplayOfCommentReplies(commentId, core, false);
      }
      // remove comment from history
      comment.remove();

      // Change the resolved status of the comment
      const spanElement = document.querySelector(`span[data-id="${comment.getAttribute('data-id')}"]`);
      if (spanElement) {
        spanElement.style.backgroundColor = '#FEF2CD';
        spanElement.setAttribute('data-isresolved', false);

        this.markRepliesAsResolved(commentId, spanElement, core, false);
      }
    }
  },

  handleDisplayOfCommentReplies: function (commentId, core, hide) {
    const modal = core.context.commentSystem.modal;
    const ulElement = modal.querySelector('.comments');
    const replies = ulElement.querySelectorAll(`.comment[data-id^="${commentId}-"]`);
    replies.forEach(reply => {
      if (hide) {
        reply.style.display = 'none'; // Hide replies
      } else {
        reply.style.display = 'block'; // Show replies
        reply.classList.remove('resolvedComment')
        reply.setAttribute('data-isresolved', false)

      }
    });
  },

  handleReply: function (comment, core, replyInput) {
    const replyText = replyInput.value;

    if (replyText.trim() !== "") {
      this.addReply(comment, replyText, core);
    } else {
      alert("Por favor, escribe una respuesta.");
    }
  },

  getParentComment: function (commentId, core) {
    const {
      comments
    } = core.context.commentSystem;
    // Helper function to recursively search for the comment by its ID
    const findCommentById = (id, commentList) => {
      for (const comment of commentList) {
        if (comment.commentId == id) {
          return comment; // Return the comment if found
        }
        if (comment.replies && comment.replies.length > 0) {
          const nestedComment = findCommentById(id, comment.replies);
          if (nestedComment) {
            return nestedComment; // Return if found within replies
          }
        }
      }
      return null; // Return null if not found
    };

    // Call the recursive helper function with the root comments
    const parentComment = findCommentById(commentId, comments);

    if (!parentComment) {
      console.error(`Parent comment with ID ${commentId} not found.`);
    }

    return parentComment;
  },

  addReply: function (parentCommentID, replyText, core) {
    const modal = core.context.commentSystem.modal;
    const parentComment = this.getParentComment(parentCommentID, core, modal);
    // Generate a new ID for the reply based on the parent comment's ID and its reply count
    const level = parentComment.level + 1;
    const replyCount = parentComment.replies.length + 1;
    const commentId = `${parentComment.commentId}-${replyCount}`;

    const user = JSON.parse(localStorage.getItem("user")).name || 'Autor';

    const newReply = {
      selectedText: parentComment.selectedText,
      comment: replyText,
      author: user,
      date: new Date().toLocaleString(),
      replies: [],
      level: level,
      commentId: commentId,
      isResolved: false
    };

    // Add the new reply to the parent comment's replies array
    parentComment.replies.push(newReply);
    // Update the comment's span data to store the new replies
    const commentSpan = document.querySelector(`span[data-id="${parentComment.commentId}"]`);
    if (commentSpan) {
      let repliesArray = JSON.parse(commentSpan.getAttribute('data-replies') || '[]');
      repliesArray.push(newReply);
      commentSpan.setAttribute('data-replies', JSON.stringify(repliesArray)); // Re-stringify after modification
    }

    // Append the new reply to the comment list in the modal
    this.addCommentToList(newReply, modal, core);
  },

  /**
   * Add a comment to the list of comments in the modal, with the appropriate level of indentation
   * and event listeners for the reply and done buttons.
   * @param {object} comment - The comment to add, with the properties selectedText, comment, author, date, replies, level, and commentId
   * @param {HTMLElement} modal - The modal element containing the comment list
   * @param {object} core - The core object containing the current state of the SunEditor instance
   */
  addCommentToList: function (comment, modal, core) {
    const commentList = modal.querySelector('.comments');
    const commentItem = document.createElement('li');
    const isResolved = comment.isResolved;
    let className = 'comment level-' + comment.level;
    if (isResolved == 'true' || isResolved == true) {
      className += ' resolvedComment'
    }
    commentItem.className = className;
    commentItem.setAttribute('data-id', comment.commentId);

    // Adjust indentation for replies based on the comment level
    commentItem.style.marginLeft = `${comment.level * 20}px`;
    const replySection = comment.level === 0 ?
      `
    <div class="buttons-float-comment-actions">
      <a class="done"><img src="https://s3.us-west-2.amazonaws.com/cdn.hotwaresc.com/itzamna/static/media/doneIcon.svg" alt="Done" width="20" height="20"></a>
      <a class="reply"><img src="https://s3.us-west-2.amazonaws.com/cdn.hotwaresc.com/itzamna/static/media/commentIcon.svg" alt="Reply" width="20" height="20"></a>
   </div>
      ` :
      '';

    commentItem.innerHTML = `
    <div class="meta" data-id="${comment.commentId}"  style="color: #989898; font-size: 13.5px !important">
      <div class="comment-user-info"> 
        <h4>${comment.author}</h4>
        <p> ${comment.date} <p> 
      </div>
      ${replySection}
    </div>
    <div class="body">${comment.comment}</div>
    <div class="reply-input-wrapper" style="display: none;" data-id="${comment.commentId}">
      <input class="reply-input-id-${comment.commentId}" placeholder="Responder..."></input>
      <button class="comment-add-btn" data-id="${comment.commentId}" style="height:30px" >Responder</button>
  </div>
 
  `;

    if (comment.level === 0) {
      const replyButton = commentItem.querySelector('.reply');
      replyButton.addEventListener('click', () => {
        const replyInput = commentItem.querySelector('.reply-input-wrapper');
        replyInput.style.display = (replyInput.style.display === 'none') ? 'block' : 'none';
      });
      commentList.appendChild(commentItem);
      if (isResolved) {
        this.addCommentToHistory(commentItem, core)
      }
    } else {
      const parentCommentItem = commentList.querySelector(`[data-id="${comment.commentId.split('-').slice(0, -1).join('-')}"]`);
      if (parentCommentItem) {
        parentCommentItem.insertAdjacentElement('afterend', commentItem);
      } else {
        console.error('Parent comment not found for reply:', comment);
      }
    }

  },
  /**
   * Save the comment and add it to the list of comments.
   * Creates a new comment object with selected text, comment, author, date, and replies, and level of comment. 
   * (level = 1 for root comment, level = 2-4 for replies for indexing purposes)
   * @param {Object} core The SunEditor core object.
   * @returns {boolean} True if the comment was successfully saved and added to the list.
   */
  saveComment: function (core) {
    const modal = core.context.commentSystem.modal;
    const commentText = modal.querySelector(".comment-input").value;
    const selectedText = window.getSelection().toString();
    const user = JSON.parse(localStorage.getItem("user")).name || 'Autor';
    let commentId = 0;
    if (core.context.commentSystem.comments.length > 0) {
      commentId = core.context.commentSystem.comments.length
    }

    // Check if there is selected text
    if (!selectedText) {
      alert("Porfavor, selecciona el texto que deseas comentar.");
      return false;
    }
    if (commentText.trim() === "") {
      alert("Porfavor, escribe un comentario.");
      return false;
    }
    const newComment = {
      selectedText: selectedText,
      comment: commentText,
      author: user,
      date: new Date().toLocaleString(),
      replies: [],
      level: 0,
      commentId: commentId,
      isResolved: false
    };

    core.context.commentSystem.comments.push(newComment);
    this.highlightText(newComment, core);
    this.addCommentToList(newComment, modal, core);

    return true;
  },

  /**
   * Highlights the selected text by creating a yellow <span> element
   * and adding an attribute for each property of the newComment object : 
   * author, comment, date, id, status and replies.
   * The selected text is then replaced with the new <span> element.
   * @memberof commentSystem
   * @function highlightText
   * @param {Object} newComment The comment being added to the editor.
   * @param {Object} core The SunEditor core object.
   */
  highlightText: function (newComment, core) {
    const newNode = document.createElement("span");
    newNode.style.backgroundColor = "#FEF2CD";

    newNode.setAttribute("data-author", newComment.author);
    newNode.setAttribute("data-comment", newComment.comment);
    newNode.setAttribute("data-date", newComment.date);
    newNode.setAttribute("data-replies", JSON.stringify(newComment.replies));
    newNode.setAttribute("data-id", newComment.commentId);
    newNode.setAttribute("data-isResolved", newComment.isResolved);

    newNode.textContent = newComment.selectedText;
    newNode.addEventListener("click", (event) => {
      this.handleCommentClick(event);
    })
    const range = window.getSelection().getRangeAt(0);
    range.deleteContents();
    range.insertNode(newNode);
  },

  clearHighlight: function () {
    const highlighted = document.querySelector('.highlighted');
    if (highlighted) {
      highlighted.classList.remove('highlighted');
    }
  },

  /**
   * Handle click events on commented text in the editor.
   * If the clicked element has a 'data-id' attribute and 'data-isresolved' is false,
   * highlight the corresponding comment in the modal.
   * @param {MouseEvent} event The mouse click event.
   * @param {Object} core The SunEditor core object.
   */

  handleCommentedTextActions: function (event, core) {
    const commentId = event.target.getAttribute('data-id');
    const isResolved = event.target.getAttribute('data-isresolved');
    if (commentId && isResolved === 'false') {
      this.handleCommentClick(event, core, commentId);
    }
  },

  /**
   * When a highlighted text is clicked, highlight comment at the modal,
   * @memberof commentSystem
   * @function onclickHighlightedText
   * @param {MouseEvent} event The mouse click event.
   */
  handleCommentClick: function (event) {
    const span = event.target;
    const commentId = span.getAttribute('data-id');
    const isResolved = span.getAttribute('data-isresolved');
    if (isResolved === 'true') {
      return;
    }
    span.style.backgroundColor = '#FCBC03';

    // Clear any previous highlights
    this.clearHighlight();

    // Find the matching comment in the comments list by data-id
    const targetComment = document.querySelector(`li[data-id="${commentId}"]`);

    if (targetComment) {
      targetComment.classList.add('highlighted');
      targetComment.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
      setTimeout(() => {
        targetComment.classList.remove('highlighted');
        span.style.backgroundColor = '#FEF2CD';
      }, 3000);
    }
  },

  editComment: function (tooltip, oldCommentText) {
    const newCommentText = prompt("Edit your comment:", oldCommentText);
    if (newCommentText) {
      // Update the comment in the tooltip and in the stored comments
      tooltip.querySelector("p").innerText = newCommentText;
      const comment = this.context.commentSystem.comments.find(
        (c) => c.comment === oldCommentText
      );
      if (comment) {
        comment.comment = newCommentText;
      }
    }
  },

  /**
   * Toggle the comment modal and adjust the editor's size accordingly.
   * @memberof commentSystem
   * @function openModal or close comentSystem modal. 
   * @param {Object} core The SunEditor core object.
   * @example
   * editor.commentSystem.openModal(editor.core);
   */
  openModal: function (core) {
    const modal = core.context.commentSystem.modal;
    const editor = document.querySelector(".sun-editor");
    const commentList = modal.querySelector(".comments");

    // Clear existing comments from the modal
    commentList.innerHTML = "";

    // Get all span elements in the editor with comment data
    const commentSpans = editor.querySelectorAll("span[data-comment]");

    if (commentSpans.length === 0) {
      // Display "sin comentarios" message and icon if no comments exist
      commentList.innerHTML = `
        <li style="text-align: center; color: gray;" class="no-comments">
          <img src="https://s3.us-west-2.amazonaws.com/cdn.hotwaresc.com/itzamna/static/media/empty-inbox.svg" alt="Sin comentarios" width="30" height="30">
          <p>Sin comentarios</p>
        </li>
      `;
    } else {

      // Iterate over each comment span and load the comment data into the modal
      commentSpans.forEach((span) => {
        const replies = (span.getAttribute("data-replies")) ? span.getAttribute("data-replies").replace(/(&quot\;)/g, "\"") : [];
        const comment = {
          commentId: span.getAttribute("data-id"),
          author: span.getAttribute("data-author"),
          date: span.getAttribute("data-date"),
          comment: span.getAttribute("data-comment"),
          replies: replies,
          isResolved: span.getAttribute("data-isresolved"),
          level: 0 // Root comment level
        };

        this.addCommentToList(comment, modal, core);
        // Recursively load replies (if any)
        this.loadReplies(comment, modal, core);
      });
    }

    // Toggle modal and editor size
    // if (modal.style.display === "block") {
    //   modal.style.display = "none";
    //   editor.style.width = "100%";
    //   editor.style.float = "none";
    // } else {
    //   editor.style.width = "70%";
    //   editor.style.float = "left";
    //   modal.style.display = "block";
    // }
    if (modal.style.display === "block") {
      modal.style.display = "none";
      editor.style.width = "100%";
    } else {
      modal.style.display = "block";
    }
  },

  /**
   * Recursively loads replies (if any) for a given comment.
   * @memberof commentSystem
   * @function loadReplies
   * @param {Object} comment The comment object with replies to load.
   * @param {Object} modal The modal element to add the replies to.
   * @param {Object} core The SunEditor core object.
   */
  loadReplies: function (comment, modal, core) {
    if (comment) {
      core.context.commentSystem.comments.push(comment);
    };

    if (comment.replies && comment.replies.length > 0) {
      if (typeof comment.replies === 'string') {
        comment.replies = JSON.parse(comment.replies.replace(/(&quot\;)/g, "\""));
      }
      comment.replies.forEach((reply) => {
        this.addCommentToList(reply, modal, core);

        this.loadReplies(reply, modal, core);
      });
    }
  },

  action: function () {
    if (
      !this.util.hasClass(this.context.commentSystem.targetButton, "active")
    ) {
      this.context.commentSystem.openModal(this);
    } else {
      this.nodeChange(null, ["background-color"], ["span"], true);
    }
  },
};

export default commentPlugin;
