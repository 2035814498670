
import comment_plugin from "./comment";
import commentSystem from "./commentSystem";
import 'suneditor/dist/css/suneditor.min.css'
import plugins from 'suneditor/src/plugins'


const getDefaultOptions = () => {
  return {
    display: "block",
    width: "100%",
    height: "auto",
    textTags: {
      bold: "b",
      italic: "i",
    },
    fontSize: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
    ],
    font: [
    //   "Arial",
    //   "Calibri",
    //   "Cambria",
    //   "Helvetica",
      "Noto Sans","Montserrat, sans-serif",
      "Open Sans",
      "Roboto",
      "Tinos"
    //   "Times New Roman",
    ],
    defaultStyle: "font-size:25pt;",
    fontSizeUnit: "pt",
    popupDisplay: "full",
    charCounter: true,
    charCounterLabel: "Caractares :",
    showPathLabel: false,
    plugins: {commentSystem, ...plugins},
    buttonList: [
      // default
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle", "blockquote"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor", "textStyle"],
      ["removeFormat"],
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      ["table", "link", "image"],
      ["fullScreen", "showBlocks", "codeView"],
      ["preview"],
      ["template"],
      ["commentSystem"],
    
      // (min-width: 1546)
      [
        "%1546",
        [
          ["undo", "redo"],
          ["font", "fontSize", "formatBlock"],
          ["paragraphStyle", "blockquote"],
          ["bold", "underline", "italic", "strike", "subscript", "superscript"],
          ["fontColor", "hiliteColor", "textStyle"],
          ["removeFormat"],
          ["outdent", "indent"],
          ["align", "horizontalRule", "list", "lineHeight"],
          ["table", "link", "image"],
          ["fullScreen", "showBlocks", "codeView"],
          [
            "-right",
            ":i-More Misc-default.more_vertical",
            "preview",
            "template",
            "commentSystem",
          ],
        ],
      ],
      // (min-width: 1455)
      [
        "%1455",
        [
          ["undo", "redo"],
          ["font", "fontSize", "formatBlock"],
          ["paragraphStyle", "blockquote"],
          ["bold", "underline", "italic", "strike", "subscript", "superscript"],
          ["fontColor", "hiliteColor", "textStyle"],
          ["removeFormat"],
          ["outdent", "indent"],
          ["align", "horizontalRule", "list", "lineHeight"],
          ["table", "link", "image"],
          [
            "-right",
            ":i-More Misc-default.more_vertical",
            "fullScreen",
            "showBlocks",
            "codeView",
            "preview",
            "template",
            "commentSystem",
          ],
        ],
      ],
      // (min-width: 1326)
      // [
      //   "%1326",
      //   [
      //     ["undo", "redo"],
      //     ["font", "fontSize", "formatBlock"],
      //     ["paragraphStyle", "blockquote"],
      //     ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      //     ["fontColor", "hiliteColor", "textStyle"],
      //     ["removeFormat"],
      //     ["outdent", "indent"],
      //     ["align", "horizontalRule", "list", "lineHeight"],
      //     [
      //       "-right",
      //       ":i-More Misc-default.more_vertical",
      //       "fullScreen",
      //       "showBlocks",
      //       "codeView",
      //       "preview",
      //       "template",
      //      "commentSystem",
      //     ],
      //     [
      //       "-right",
      //       ":r-More Rich-default.more_plus",
      //       "table",
                //TODO : Custom plugin of commenting system will not work if the link plugin is in a dropdown menu
      //       // "link", 
      //       "image",
      //       "imageGallery",
      //     ],
      //   ],
      // ],
      // // (min-width: 1123)
      [
        "%1194",
        [
          ["undo", "redo"],
          [
            ":p-More Paragraph-default.more_paragraph",
            "font",
            "fontSize",
            "formatBlock",
            "paragraphStyle",
            "blockquote",
          ],
          ["bold", "underline", "italic", "strike", "subscript", "superscript"],
          ["fontColor", "hiliteColor", "textStyle"],
          ["removeFormat"],
          ["outdent", "indent"],
          ["align", "horizontalRule", "list", "lineHeight"],
          ["table", "link", "image"],
          [
            "-right",
            ":i-More Misc-default.more_vertical",
            "fullScreen",
            "showBlocks",
            "codeView",
            "preview",
            "template",
           "commentSystem",
          ],
          // [
          //   "-right",
          //   ":r-More Rich-default.more_plus",
          //   "table",
          //   "link",
          //   "image",
          //   "imageGallery",
          // ],
        ],
      ],
      // // (min-width: 817)
      // [
      //   "%817",
      //   [
      //     ["undo", "redo"],
      //     [
      //       ":p-More Paragraph-default.more_paragraph",
      //       "font",
      //       "fontSize",
      //       "formatBlock",
      //       "paragraphStyle",
      //       "blockquote",
      //     ],
      //     ["bold", "underline", "italic", "strike"],
      //     [
      //       ":t-More Text-default.more_text",
      //       "subscript",
      //       "superscript",
      //       "fontColor",
      //       "hiliteColor",
      //       "textStyle",
      //     ],
      //     ["removeFormat"],
      //     ["outdent", "indent"],
      //     ["align", "horizontalRule", "list", "lineHeight"],
      //     [
      //       "-right",
      //       ":i-More Misc-default.more_vertical",
      //       "fullScreen",
      //       "showBlocks",
      //       "codeView",
      //       "preview",
      //       "template",
      //      "commentSystem",
      //     ],
      //     [
      //       "-right",
      //       ":r-More Rich-default.more_plus",
      //       "table",
      //       "link",
      //       "image",
      //       "imageGallery",
      //     ],
      //   ],
      // ],
      // // (min-width: 673)
      // [
      //   "%673",
      //   [
      //     ["undo", "redo"],
      //     [
      //       ":p-More Paragraph-default.more_paragraph",
      //       "font",
      //       "fontSize",
      //       "formatBlock",
      //       "paragraphStyle",
      //       "blockquote",
      //     ],
      //     [
      //       ":t-More Text-default.more_text",
      //       "bold",
      //       "underline",
      //       "italic",
      //       "strike",
      //       "subscript",
      //       "superscript",
      //       "fontColor",
      //       "hiliteColor",
      //       "textStyle",
      //     ],
      //     ["removeFormat"],
      //     ["outdent", "indent"],
      //     ["align", "horizontalRule", "list", "lineHeight"],
      //     [
      //       ":r-More Rich-default.more_plus",
      //       "table",
      //       "link",
      //       "image",
      //       "imageGallery",
      //     ],
      //     [
      //       "-right",
      //       ":i-More Misc-default.more_vertical",
      //       "fullScreen",
      //       "showBlocks",
      //       "codeView",
      //       "preview",
      //       "template",
      //       "commentSystem",
      //     ],
      //   ],
      // ],
      // (min-width: 525)
      [
        "%525",
        [
          ["undo", "redo"],
          [
            ":p-More Paragraph-default.more_paragraph",
            "font",
            "fontSize",
            "formatBlock",
            "paragraphStyle",
            "blockquote",
          ],
          [
            ":t-More Text-default.more_text",
            "bold",
            "underline",
            "italic",
            "strike",
            "subscript",
            "superscript",
            "fontColor",
            "hiliteColor",
            "textStyle",
          ],
          ["removeFormat"],
          ["outdent", "indent"],
          [
            ":e-More Line-default.more_horizontal",
            "align",
            "horizontalRule",
            "list",
            "lineHeight",
          ],
          [
            ":r-More Rich-default.more_plus",
            "table",
            "link",
            "image",
            "imageGallery",
          ],
          [
            "-right",
            ":i-More Misc-default.more_vertical",
            "fullScreen",
            "showBlocks",
            "codeView",
            "preview",
            "template",
            "commentSystem",
          ],
        ],
      ],
      // (min-width: 420)
      [
        "%420",
        [
          ["undo", "redo"],
          [
            ":p-More Paragraph-default.more_paragraph",
            "font",
            "fontSize",
            "formatBlock",
            "paragraphStyle",
            "blockquote",
          ],
          [
            ":t-More Text-default.more_text",
            "bold",
            "underline",
            "italic",
            "strike",
            "subscript",
            "superscript",
            "fontColor",
            "hiliteColor",
            "textStyle",
            "removeFormat",
          ],
          [
            ":e-More Line-default.more_horizontal",
            "outdent",
            "indent",
            "align",
            "horizontalRule",
            "list",
            "lineHeight",
          ],
          [
            ":r-More Rich-default.more_plus",
            "table",
            "link",
            "image",
            "imageGallery",
          ],
          [
            "-right",
            ":i-More Misc-default.more_vertical",
            "fullScreen",
            "showBlocks",
            "codeView",
            "preview",
            "template",
            "commentSystem",
          ],
        ],
      ],
    ],
    placeholder: "...",
    templates: [
      {
        name: "Encabezado",
        html: `<table style="border: 1px solid black;width: 100%;background-color: rgb(0, 102, 102);color:rgb(255,255,255)">
                <tr style="border-spacing: 0px;background-color: rgb(0, 102, 102);">
                    <th style="text-align: center;background-color: rgb(0, 102, 102);">
                        
                    </th>
                </tr>
            </table>`,
      },
    ],
  };
};

export { getDefaultOptions };
